.container {
    width: 75%;
}

img {
    max-width: 100%;
}

$primaryFont: 'Inter';
$secondaryFont: 'Space Grotesk';
$petronaFont: 'Petrona';

body {
    font-family: $primaryFont;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

a:focus-visible {
    outline: none;
}

a:hover {
    color: unset;
}

iframe {
    display: none !important;
}

input:focus-visible {
    outline: none;
}

ul {
    list-style: none;
}


.accordion-button:not(.collapsed) {
    box-shadow: none;
}

.accordion-item:first-of-type .accordion-button {
    border-radius: 0;
    box-shadow: none;
}

.accordion-button {
    background-color: unset !important;
}

.accordion-button:focus-visible {
    outline: none;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: unset;
}

.accordion-item {
    background-color: unset;
    border: 0;
    border-radius: 0;
}

.nav.nav-tabs {
    border: 0;
}

.nav.nav-tabs .nav-link {
    border: 0;
    color: inherit;
    padding: 0;
}

.nav.nav-tabs .nav-link.active {
    outline: none;
    border: 0;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
}

.flex_align {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex_bt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fw700 {
    font-weight: 700 !important;
}

.fw600 {
    font-weight: 600 !important;
}

.fw500 {
    font-weight: 500 !important;
}

.fw400 {
    font-weight: 400 !important;
}

.fw300 {
    font-weight: 300 !important;
}

h1 {
    font-weight: 700;
    font-size: clamp(22px, 2.5vw, 42px);
    line-height: 120%;
    font-family: $secondaryFont;
}

// page css start 
.heroMain {
    position: relative;
    z-index: 99;

    &::before {
        content: "";
        background-image: url(./img/HeroMain.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
}

.heroSec {
    height: 100vh;
    position: relative;

    &.heroSec__ {
        .navbar {
            background-color: #fff;
            transition: all 0.3s ease-in-out;
            box-shadow: 0px 4px 63px 0px #EEEFF8;
        }

        .navbar-brand {
            display: block;
        }
    }

    .navbar {
        height: 140px;
        padding: 0 !important;
        background: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(40px);
        position: fixed;
        width: 100%;
        z-index: 999999;

        .navBtn {
            padding: 0 55px;
            font-size: 16px;
            border-radius: 14px;
            font-weight: 700;
            z-index: 99999999;
            cursor: pointer;
        }
    }

    .navbar-brand {
        max-width: 115px;
        width: 100%;
        display: none;
        z-index: 99999999;
    }

    .nav-link {
        padding: 0 !important;
        color: #2D2147;
        font-weight: 400;
        font-size: 18px;
        line-height: 167%;
        z-index: 99999999;
    }

    .navbar-nav {
        gap: 50px;
        align-items: center;
        z-index: 99999999;
    }

    .heroContainer {
        height: 100%;
    }
}

.navbarMob {
    .show {
        display: none !important;
    }

    .navbar-toggler.collapsed .navbar-toggler-icon {
        background-image: url(./img/hamburger.svg) !important;
    }
}

.mainBtn {
    width: fit-content;
    padding: 0 24px;
    font-size: clamp(16px, 2vw, 24px);
    font-weight: 600;
    border-radius: 90px;
    gap: 8px;

    img {
        width: 18px;
    }
}

.heroInner {
    max-width: 950px;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .birdImg {
        position: absolute;
        top: 180px;
        left: -55px;
    }

    .heroLogo {
        max-width: 370px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 7px;

        img {
            width: 100%;
        }
    }

    h3 {
        color: #2D2147;
        font-family: $secondaryFont;
        max-width: 850px;
        align-self: center;
        font-size: 32px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.64px;
    }

    h1 {
        font-size: 22px;
        margin-bottom: 70px;
        font-weight: 400;
    }
}

.needWrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .needBox {
        width: calc(33.33% - 10px);
        border-radius: 24px;
        text-align: center;
        padding: 30px 25px;

        p {
            color: #2D2147;
            font-size: 18px;
            font-weight: 400;
            line-height: 167%;
            min-height: 150px;
            margin-top: 20px;
        }

        .needImg {
            max-width: 350px;
            width: 100%;
            height: 230px;
            margin-left: auto;
            margin-right: auto;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

// heroNxt sec start 
.heroNxt {
    position: relative;
    z-index: 1;

    .heroNxtInner {
        padding-bottom: 150px;

        h1 {
            font-size: clamp(36px, 2.5vw, 42px);
        }
    }

    h1 {
        color: #161616;
        text-align: center;
        font-family: $secondaryFont;
        font-weight: 700;
        line-height: 125%;
        margin-bottom: 35px;
    }
}



// solutionWrap sec start 
.solutionWrap {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    margin-bottom: clamp(40px, 12vw, 200px);

    .phoneImg {
        max-width: 500px;
        width: 100%;
    }

    h1 {
        color: #8FF3B7;
        text-align: start;
        margin-bottom: 0;
    }

    h2 {
        color: #FFF;
        font-family: $secondaryFont;
        font-size: 24px;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.64px;
    }

    p {
        color: #FFF;
        font-size: 22px;
        font-weight: 400;
        line-height: 150%;
    }

    li {
        &+li {
            margin-top: 24px;
        }
    }

    ul {
        max-width: 660px;
        width: 100%;
        padding-top: 30px;
    }
}

.slick-track {
    display: flex;
    align-items: center;
}

.caseSliderContainer {
    position: relative;

    .slick-disabled {
        &.slick-arrow {
            opacity: 0.5;
        }
    }

    h1 {
        text-align: center;
        color: #FFF;
        margin-bottom: 0;
    }

    .slick-arrow {
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #DBE0FF;
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(3.6666667461395264px);
        display: flex !important;
        justify-content: center;
        align-items: center;
        z-index: 99;

        &::before {
            display: none;
        }

        img {
            width: 15px;
        }

        &.slick-prev {
            bottom: -65px;
            left: 46%;
            transform: translateX(-50%);
            top: auto;
        }

        &.slick-next {
            bottom: -65px;
            left: 54%;
            transform: translateX(-50%);
            top: auto;
        }
    }

    .slick-dots {
        position: unset;
        display: flex !important;
        justify-content: center;
        margin-top: 10px;
        gap: 16px;

        li {
            width: auto;
            height: auto;
            margin: 0;

            &.slick-active {
                button {
                    background-color: #fff;
                }
            }
        }

        button {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background-color: #2D2147;

            &::before {
                display: none;
            }


        }
    }

    .slick-center {
        transform: scale(1.13) !important;
        z-index: 2;
        opacity: 1 !important;
        position: relative;
        z-index: 9;
        transition: transform 0.5s ease, opacity 0.5s ease;
    }

    .slick-slide {
        position: relative;
        transition: transform 0.3s ease-in-out;
        border-radius: 20px;
        background: #FFF;
        opacity: 0.5;
        transform: scale(0.9);
        transition: transform 0.5s ease, opacity 0.5s ease;


        .caseSlide.slick-left {
            transform: scale(0.9) translateX(88%) !important;
        }

        .caseSlide.slick-right {
            transform: scale(0.9) translateX(-88%) !important;
        }
    }

    .slick-list {
        height: 100% !important;
        padding: 60px 0 !important;
    }

    .caseSlider {
        position: unset;
    }

    .sliderImg {
        max-width: 430px;
        width: 100%;
        position: relative;

        .slidesCount {
            border-radius: 50%;
            border: 1.506px solid #DBE0FF;
            background: rgba(255, 255, 255, 0.9);
            color: #2D2147;
            width: 70px;
            height: 70px;
            font-size: 24px;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.482px;
            position: absolute;
            top: 15px;
            right: 15px;
        }

        img {
            border-radius: 0px 20px 20px 0px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .caseSlideInner {
        max-width: 535px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 60px;
        padding-left: 40px;
        padding-right: 30px;
        padding-bottom: 50px;

        p {
            color: #2D2147;
            font-size: 22.72px;
            font-weight: 400;
            line-height: 167%;

            &+p {
                margin-top: 15px;
            }
        }

        h1 {
            color: #2D2147;
            font-size: clamp(20px, 2.5vw, 45px);
            letter-spacing: -0.909px;
            margin-bottom: 20px;
            text-align: start;
        }

        img {
            max-width: 190px;
            width: 100%;
        }
    }

    .caseSlide {
        display: flex !important;
        border-radius: 20px;

    }
}

// boatWrap sec start 
.boatWrap {
    position: relative;

    .boatImg {
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
        max-width: 800px;
        width: 100%;
    }

    ul {
        width: 55%;
        margin-left: auto;
        padding-top: clamp(40px, 10vw, 180px);
        padding-bottom: clamp(40px, 10vw, 180px);
        margin-top: 20px;

        li {
            display: flex;
            gap: 16px;
            align-items: start;

            &+li {
                margin-top: 35px;
            }
        }

        p {
            color: #2D2147;
            font-size: 22px;
            font-weight: 400;
            line-height: 150%;
        }
    }
}

// partnerWrap sec start 
.partnerWrap {
    position: relative;
    z-index: 2;
    padding-top: 50px;
    padding-bottom: clamp(40px, 11vw, 190px);

    .slick-slide {
        margin: 0 10px;

        img {
            max-width: 200px;
            width: 100%;
        }
    }

    .slick-track {
        align-items: center !important;
    }

    h1 {
        color: #2D2147;
        text-align: center;
        font-size: clamp(22px, 2.5vw, 42px);
        margin-bottom: 35px;
    }

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 18px;
    }


}

// servicesWrap sec start 
.serviceBg {
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        background-image: url(./img/servicesBg.jpg);
        background-repeat: no-repeat;
        background-size: 100%;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: -1;
    }
}

.servicesWrap {


    &::after {
        content: "";
        background-image: url(./img/clouds_and_sun.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 675px;
        height: 420px;
        position: absolute;
        bottom: 70px;
        left: 0;
        z-index: -1;
    }

    h1 {
        color: #2D2147;
        font-size: clamp(22px, 2.5vw, 42px);
        margin-bottom: 35px;
        text-align: center;

    }

    ul {
        display: flex;
        justify-content: space-between;
        padding-bottom: clamp(40px, 11vw, 220px);

        li {
            width: calc(20% - 8px);
            border-radius: 20px;
            background: #FFF;
            text-align: center;
            padding: 20px 25px;
            box-shadow: 0px 4px 63px 0px #EEEFF8;
        }

        h1 {
            margin-top: 20px;
            margin-bottom: 0;
            color: #2D2147;
            font-size: 40px;
            font-weight: 600;
            font-family: $petronaFont;
        }

        span {
            color: #2D2147;
            text-align: center;
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 167%;
            position: relative;
            padding-bottom: 12px;
            margin-bottom: 12px;

            &::before {
                content: "";
                width: 30px;
                height: 2px;
                background-color: #2D2147;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
            }
        }

        p {
            color: #2D2147;
            font-size: 16px;
            font-weight: 400;
            line-height: 167%;
        }

        &.teamRow {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;

            li {
                width: calc(22% - 5px);
                padding: 40px 25px 60px 25px;
                position: relative;
            }

            .teamImg {
                max-width: 125px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }

            h1 {
                color: #2D2147;
                margin: 15px 0;
                font-size: 26px;
                letter-spacing: -0.64px;
                font-family: $secondaryFont;
            }

            span {
                a {
                    font-size: 16px;
                    font-weight: 700;
                }

                &::before {
                    display: none;
                }
            }

            .linkedIcon {
                width: 40px;
                display: block;
                margin: auto;

                img {
                    width: 100%;
                }
            }
        }
    }

    .serviceImg {
        height: 60px;
        max-width: 70px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        img {
            height: 100%;
        }
    }
}

// footerU sec start 
.footerU {
    position: relative;
    z-index: 2;
    padding-top: 50px;

    &::before {
        content: "";
        background-image: url(./img/lighthouse.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: -1;
    }


    form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 50%;
        margin-left: auto;
        gap: 15px 0;
        padding-bottom: 500px;


        h1 {
            color: #FFF;
            width: 100%;
            font-size: clamp(22px, 2.5vw, 42px);
        }

        .formGroup {
            width: 100%;

            &.w50 {
                width: calc(50% - 7.5px);
            }

            label {
                color: #FFF;
                font-size: 16px;
                font-weight: 400;
                line-height: 167%;
                margin-bottom: 8px;
                display: block;
            }

            input {
                height: 55px;
            }

            textarea {
                height: 85px;
                margin-bottom: 15px;
            }
        }

        .submitBtn {
            padding: 0 73px;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.32px;
            cursor: pointer;
        }
    }
}

// .footerWrap sec start 
.footerWrap {
    background: #DBE0FF;
    padding-top: 85px;
    padding-bottom: 85px;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    p {
        color: #2D2147;
        font-size: 14px;
        font-weight: 400;
        line-height: 167%;
    }

    .comText {
        font-size: 12px;
    }

    .comInnerText {
        font-size: 12px;
        color: transparent;
    }

    ul {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    .mainLogo {
        max-width: 172px;
        width: 100%;

        img {
            width: 100%;
        }
    }

    .hippalogo {
        max-width: 172px;
        width: 100%;
        display: flex;
        justify-content: center;
        // margin-left: 40%;

        img {
            width: 130%;
            max-width: 130%;
            align-self: center;
        }
    }
}

.caseSliderContainer.caseSliderContainerFooter {
    max-width: 950px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: clamp(90px, 15vw, 230px);

    .slick-center {
        transform: scale(1.05) !important;
    }

    .slick-track {
        align-items: center;
    }

    .slick-list {
        padding: 70px 0 !important;
    }

    .slick-slide .caseSlide {
        max-width: 307px;
        width: 100%;
    }

    .slick-slide.slick-center .caseSlide {
        max-width: 454px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .slick-center .caseSlideInner {
        padding: 30px 20px;
        box-shadow: 0px 4px 63px 0px #EEEFF8;
        max-width: 100%;
        border-radius: 20px;
        opacity: 1;
        background: #FFF;

        p {
            font-size: 20px;
        }

        span {
            font-size: 16px;
        }
    }

    .caseSlideInner {
        padding: 20px 25px 10px 25px;
        max-width: 100%;
        border-radius: 13.524px;
        opacity: 0.5;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 2.70485px 42.60132px 0px #EEEFF8;
    }


    .caseSlideInner img {
        max-width: 55px;
        opacity: 0.4;
        padding-left: 5px;
    }

    .caseSlideInner p {
        margin-top: 10px;
        margin-bottom: 15px;
        color: #2D2147;
        font-family: $secondaryFont;
        font-weight: 400;
        line-height: 150%;
        min-height: auto;
        font-size: 13.524px;
    }

    .caseSlideInner span {
        color: #2D2147;
        font-size: 10.819px;
        font-weight: 400;
        line-height: 167%;
    }

    .slick-slide .caseSlide.slick-left {
        transform: translateX(25%) !important;
    }

    .slick-slide .caseSlide.slick-right {
        transform: translateX(-25%) !important;
    }

    .slick-dots {
        display: none !important;
    }

    h1 {
        color: #000;
        text-align: center;
        font-size: clamp(22px, 2.5vw, 42px);
    }

    .slick-arrow.slick-next {
        bottom: -40px !important;
        top: auto !important;
        right: auto;
        left: 55%;
        transform: translateX(-50%);
        z-index: 9;
    }

    .slick-arrow.slick-prev {
        bottom: -40px !important;
        top: auto;
        right: auto;
        left: 45%;
        transform: translateX(-50%);
        z-index: 9;
    }
}

.servicesWrap ul.teamRow li p {
    display: none;
}


// responsive part start 
@media (max-width: 1690px) {
    .solutionWrap {
        padding: 0 30px;
        align-items: center;
    }

    .solutionWrap .phoneImg {
        text-align: end;

        img {
            object-fit: cover;
            height: 100%;
        }
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-center .caseSlideInner p {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-center .caseSlideInner {
        padding: 20px 15px;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-list {
        padding: 45px 0 !important;
    }

    .heroInner h1 {
        font-size: 20px;
    }

    .caseSliderContainer .caseSlideInner {
        padding: 40px 30px 30px 40px;
    }

    .servicesWrap ul.teamRow li {
        padding: 40px 25px 50px 25px;
    }

    .servicesWrap ul.teamRow .linkedIcon {
        bottom: 20px;
        right: 20px;
    }

    .heroInner .birdImg {
        top: 160px;
        max-width: 190px;
        width: 100%;
    }

    .heroInner h3 {
        font-size: 28px;
    }

    .needWrap .needBox .needImg {
        height: 190px;
        max-width: 300px;
    }

    .heroInner .heroLogo {
        max-width: 320px;
    }

    .heroNxt .heroNxtInner {
        padding-bottom: 400px;
    }

    .solutionWrap {
        margin-bottom: 100px;
    }

    .caseSliderContainer .slick-arrow.slick-prev {
        bottom: -50px;
    }

    .caseSliderContainer .slick-arrow.slick-next {
        bottom: -50px;
    }

    .caseSliderContainer .caseSlideInner h1 {
        font-size: 34px;
        margin-bottom: 10px;
    }

    .caseSliderContainer .sliderImg {
        max-width: 380px;
    }

    .caseSliderContainer .caseSlideInner p {
        font-size: 19px;
    }

    .heroSec .navbar {
        height: 100px;
    }
}

@media (max-width: 1560px) {
    .caseSliderContainer .caseSlideInner h1 {
        font-size: 26px;
    }

    .caseSliderContainer .caseSlideInner p {
        font-size: 16px;
    }

    .caseSliderContainer .caseSlideInner {
        padding: 25px;
    }
    .caseSliderContainer .sliderImg {
        max-width: 325px;
    }
    .caseSliderContainer .sliderImg .slidesCount {
        width: 55px;
        height: 55px;
        font-size: 18px;
        top: 10px;
        right: 10px;
    }
    .caseSliderContainer .caseSlideInner {
        max-width: 500px;
    }
    .caseSliderContainer .slick-arrow.slick-prev {
        bottom: -40px;
        min-width: 45px;
        height: 45px;
    }
    .caseSliderContainer .slick-arrow.slick-next {
        bottom: -40px;
        min-width: 45px;
        height: 45px;
    }
    .heroSec .navbar {
        height: 80px;
    }
    .caseSliderContainer .slick-list {
        padding: 50px 0 !important;
    }
    .caseSliderContainer .slick-arrow img {
        width: 13px;
    }

}
@media (max-width: 1500px) {
    .caseSliderContainer .caseSlideInner h1 {
        font-size: 22px;
    }
    .caseSliderContainer .sliderImg {
        max-width: 310px;
    }

    .servicesWrap .containerTeam {
        max-width: 100%;
        width: 100%;
    }
    
}

@media (max-width: 1370px) {

    .mainBtnTeamTitle {
        padding: 0px 12px !important;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-arrow.slick-next {
        bottom: 0px !important;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-arrow.slick-prev {
        bottom: 0px !important;
    }

    .heroSec .navbar {
        height: 72px;
    }

    .caseSliderContainer .slick-arrow.slick-prev {
        bottom: -40px;
    }

    .caseSliderContainer .slick-arrow.slick-next {
        bottom: -40px;
    }

    .caseSliderContainer .slick-list {
        padding: 40px 0 !important;
    }

    .heroInner .birdImg {
        top: 120px;
        max-width: 130px;
        width: 100%;
        left: 0;
    }

    .heroMain::before {
        background-size: 100% 100%;
    }

    .heroInner h3 {
        font-size: 22px;
        max-width: 800px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .heroInner h1 {
        font-size: 16px;
    }

    .servicesWrap ul.teamRow {
        gap: 10px;
    }


    .needWrap .needBox img {
        max-width: 200px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .heroSec .nav-link {
        font-size: 16px;
    }

    .heroNxt .heroNxtInner {
        padding-bottom: 240px;
    }

    .solutionWrap ul {
        max-width: 480px;
    }

    .caseSliderContainer h1 {
        margin-bottom: 0;
    }

    .caseSliderContainer .caseSlideInner h1 {
        font-size: 22px;
    }

    .heroSec .navbar-nav {
        gap: 30px;
    }

    .heroSec .navbar .navBtn {
        padding: 0 30px;
    }



    .heroInner .heroLogo {
        max-width: 250px;
    }

    .heroNxt .heroNxtInner {
        padding-top: 50px;
    }

    .heroNxt::before {
        top: 0;
        height: 100%;
        background-size: 100% 100%;
    }

    .needWrap .needBox p {
        font-size: 15px;
        min-height: 110px;
    }

    .solutionWrap h2 {
        font-size: 20px;
    }

    .solutionWrap p {
        font-size: 15px;
    }

    .mainBtn {
        height: 45px !important;
        font-size: 16px !important;
    }

    .caseSliderContainer .caseSlideInner p {
        font-size: 14px;
    }

    .caseSliderContainer .caseSlideInner p+p {
        margin-top: 10px;
    }

    .caseSliderContainer .caseSlideInner {
        max-width: 410px;
        padding: 25px 20px 25px 20px;
    }

    .caseSliderContainer .sliderImg {
        max-width: 250px;
    }

    .caseSliderContainer .sliderImg .slidesCount {
        width: 40px;
        height: 40px;
        font-size: 14px;
        top: 10px;
        right: 10px;
    }

    .servicesWrap ul.teamRow h1 {
        margin: 10px 0;
        font-size: 20px;
    }

    .servicesWrap ul.teamRow span a {
        height: 35px !important;
        font-size: 14px !important;
    }

    .teamImg {
        max-width: 90px;
    }

    .solutionWrap {
        padding: 0 30px;
        align-items: center;
    }

    .solutionWrap .phoneImg {
        height: 470px;
        text-align: end;

        img {
            object-fit: cover;
            height: 100%;
        }
    }

    .caseSliderContainer .slick-dots button {
        width: 13px;
        height: 13px;
    }

    .boatWrap ul p {
        font-size: 16px;
    }

    .boatWrap ul li {
        gap: 10px;

        img {
            width: 25px;
        }
    }

    .servicesWrap ul.teamRow li {
        padding: 25px 25px 40px 25px;
        width: calc(22% - 20px);
    }

    .servicesWrap ul.teamRow .linkedIcon {
        bottom: 15px;
        right: 15px;
    }

    .needWrap .needBox .needImg {
        height: 100px;
        max-width: 160px;
    }

    .servicesWrap ul li {
        padding: 15px 10px;
    }

    .servicesWrap .serviceImg {
        height: 40px;
        max-width: 40px;
    }

    .servicesWrap ul h1 {
        font-size: 26px;
    }

    .servicesWrap ul p {
        font-size: 15px;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-center .caseSlideInner p {
        font-size: 14px;
    }

    .caseSliderContainer.caseSliderContainerFooter .caseSlideInner img {
        max-width: 40px;
    }

    .caseSliderContainer.caseSliderContainerFooter .caseSlideInner p {
        margin-top: 8px;
        margin-bottom: 5px;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-center .caseSlideInner span {
        font-size: 12px;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-center .caseSlideInner {
        padding: 15px;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-slide.slick-center .caseSlide {
        max-width: 390px;
    }

    .footerU::before {
        background-size: 100% 100%;
    }

    .footerWrap {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .footerWrap .mainLogo {
        max-width: 120px;
    }

    .boatWrap .boatImg {
        left: -30px;
        max-width: 630px;
        width: 100%;
    }

    .hippalogo img {
        width: 90%;
        max-width: 90%;
    }
}

// ipad pro 
// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .caseSliderContainer.caseSliderContainerFooter .slick-list {
        padding-bottom: 80px !important;
    }

    .mainBtnTeamTitle {
        padding: 0px 12px !important;
    }

    .servicesWrap ul.teamRow span a {
        font-size: 12px !important;
    }

    .heroInner .birdImg {
        top: -50px;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-arrow.slick-next {
        bottom: 0px !important;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-arrow.slick-prev {
        bottom: 0px !important;
    }

    .heroMain::before {
        background-size: 100% 100%;
    }

    .heroInner .heroLogo {
        max-width: 200px;
    }

    .boatWrap .boatImg {
        left: 0;
        max-width: 485px;
        width: 100%;
    }

    .container {
        width: 100%;
    }

    .partnerWrap ul li {
        max-width: 150px;
        width: 100%;
    }

    .servicesWrap ul {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }

    .servicesWrap ul li {
        width: calc(25% - 8px);
    }

    .solutionWrap .phoneImg {
        max-width: 500px;
    }

    .solutionWrap {
        padding: 0;
        gap: 40px;
        align-items: center;
    }

    .servicesWrap::before {
        height: 100%;
        top: 0;
    }

    .footerU form {
        padding-bottom: 200px;
    }

    .servicesWrap ul.teamRow span a {
        font-size: 16px;
    }

}

//  ipad 
// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .heroInner h3 {
        font-size: 20px;
    }

    .heroInner h1 {
        font-size: 16px;
        margin-bottom: 55px;
    }

    .boatWrap .boatImg {
        max-width: 390px;
    }

    .boatWrap ul li+li {
        margin-top: 15px;
    }

    .servicesWrap ul li {
        width: calc(33.33% - 8px);
    }

    .servicesWrap ul.teamRow {
        gap: 15px;
    }

    .servicesWrap ul.teamRow li {
        width: calc(50% - 20px);
    }

    .heroSec .navbar {
        height: auto;
        padding: 15px 0 !important;

        &.navBar__ {
            background: #DBE0FF;
            height: 100vh;
            align-items: flex-start;
        }

        .navbar-toggler {
            padding: 0;
            border: 0;
            width: 25px;
            height: 25px;

            &:focus {
                box-shadow: none;
            }
        }

        .navbar-toggler.collapsed .navbar-toggler-icon {
            background: url(./img/hamburger.svg);
        }

        .navbar-toggler .navbar-toggler-icon {
            background: url(./img/close.svg);
            background-size: cover;
            background-repeat: no-repeat;
            width: 25px;
            height: 25px;
            position: absolute;
            z-index: 9;
            top: 27px;
            right: 20px;
        }
    }

    .heroSec .navBar__ .navbar-brand {
        display: block;
    }

    .heroSec .navbar-nav {
        gap: 25px;
        align-items: flex-start;
        margin-top: 25px;
    }

    .heroSec .navbar .navBtn {
        padding: 0 60px;
    }

    .bodyFixed {
        overflow: hidden;
    }

    .needWrap .needBox {
        width: calc(50% - 10px);
        padding: 20px;
    }

    .needWrap {
        justify-content: center;
        gap: 15px;
    }

    .solutionWrap {
        gap: 25px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .solutionWrap h2 {
        font-size: 19px;
    }

    .solutionWrap li+li {
        margin-top: 15px;
    }

    .caseSliderContainer .caseSlide {
        flex-wrap: wrap;
        justify-content: center;
    }

    .caseSliderContainer .slick-arrow.slick-next {
        bottom: -30px;
        left: 56%;
    }

    .caseSliderContainer .slick-arrow.slick-prev {
        bottom: -30px;
        right: 44%;
    }

    .caseSliderContainer .caseSlideInner {
        max-width: 100%;
        min-height: 250px;
        padding: 20px;

    }

    .caseSliderContainer .slick-slide .caseSlide.slick-right {
        transform: unset !important;
    }

    .caseSliderContainer .slick-slide .caseSlide.slick-left {
        transform: unset !important;
    }

    .caseSliderContainer .slick-slide .caseSlide {
        position: relative;
    }

    .mainBtn {
        font-size: 14px !important;
    }

    .caseSliderContainer .sliderImg {
        max-width: 100%;
        position: unset;
        height: 500px;

        img {
            border-radius: 20px 20px 0px 0px;
        }
    }

    .slick-track {
        align-items: self-start;
    }

    .caseSliderContainer .slick-slide {
        max-width: 100%;
        width: 100%;
    }

    .caseSliderContainer .slick-slide.slick-center {
        transform: unset !important;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-slide .caseSlide {
        max-width: 100% !important;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-slide .caseSlide.slick-left {
        transform: translateX(10%) !important;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-slide .caseSlide.slick-right {
        transform: translateX(-10%) !important;
    }

    .footerU form {
        width: 60%;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-center .caseSlideInner {
        min-height: auto;
        padding: 30px;
    }

    .footerWrap .hippalogo img {
        width: 90%;
        max-width: 90%;
    }
}

// mobile 
// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .heroInner .birdImg {
        display: none;
    }

    .heroInner .heroLogo {
        max-width: 200px;
    }

    .needWrap .needBox {
        width: 100%;
    }

    .heroSec::before {
        background-size: 100% 100%;
    }

    .caseSliderContainer .slick-list {
        padding: 20px 0 !important;
    }

    .caseSliderContainer .caseSlideInner h1 {
        font-size: 19px;
        margin-bottom: 8px;
        padding-right: 10px;
    }

    .caseSliderContainer .caseSlideInner {
        padding: 15px 20px;
        min-height: auto;
        height: 25vh;
    }

    .caseSliderContainer .sliderImg {
        max-width: 100%;
        height: 50vh;

        img {
            border-radius: 0;
        }
    }

    .caseSliderContainer .caseSlideInner p {
        font-size: 11px;
        padding-right: 25px;
    }

    .caseSliderContainer .caseSlideInner p+p {
        margin-top: 6px;
    }

    .caseSliderContainer .slick-arrow.slick-prev {
        top: 40%;
        right: auto;
        left: -10px;
        transform: translateY(-50%);
        z-index: 99999999;
    }

    .caseSliderContainer .slick-arrow.slick-next {
        top: 40% !important;
        right: -10px;
        left: auto;
        transform: translateY(-50%);
        z-index: 99999999;
    }

    .caseSliderContainer .slick-dots button {
        width: 7px;
        height: 7px;
    }

    .boatWrap ul {
        width: 100%;
        padding-top: 0;
        margin-top: 0;
    }

    .boatWrap .boatImg {
        position: unset;
        transform: unset;
    }

    .boatWrap ul li {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .servicesWrap ul li {
        width: 100%;
    }

    .servicesWrap ul.teamRow li {
        width: 94%;
        padding: 25px 25px 40px 25px;
    }

    .servicesWrap ul.teamRow span a {
        font-size: 14px;
        height: 25px !important;
        padding: 0 15px !important;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-list {
        overflow: visible !important;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-center .caseSlideInner p {
        font-size: 14px;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-center .caseSlideInner span {
        font-size: 11px;
    }

    .caseSliderContainer.caseSliderContainerFooter .caseSlideInner {
        min-height: auto !important;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-arrow.slick-prev {
        left: 39%;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-arrow.slick-next {
        left: 61%;
    }

    .footerU form {
        width: 100%;
    }

    .footerU form .formGroup.w50 {
        width: 100%;
    }

    .footerWrap ul {
        gap: 10px;
    }

    .footerWrap .mainLogo {
        max-width: 85px;
    }

    .footerWrap {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .heroSec .navbar {
        padding: 10px 0 !important;
    }

    .servicesWrap ul.teamRow h1 {
        font-size: 18px;
    }

    .heroInner h1 {
        font-size: 14px;
        max-width: 240px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .heroInner h3 {
        font-size: 16px;
        max-width: 285px;
        width: 100%;
    }

    .heroMain::before {
        background-image: url(./img/HeroMainMob.jpg);
    }

    .heroSec .navbar .navbar-toggler .navbar-toggler-icon {
        top: 13px;
        right: 10px;
    }

    .solutionWrap .phoneImg {
        text-align: center;
        height: 45vh;
    }

    .caseSliderContainer.caseSliderContainerFooter .caseSlideInner img {
        max-width: 40px;
    }

    .footerU::before {
        background-image: url(./img/lighthouseMob.jpg);
    }

    .heroSec .navbar-brand {
        max-width: 80px;
    }

    .caseSliderContainer .slick-dots {
        margin-top: 50px;
    }

    .solutionWrap h1 {
        font-size: 22px;
    }

    .servicesWrap ul p {
        padding: 0 10px;
    }

    .servicesWrap ul.teamRow .linkedIcon {
        position: unset;
        width: 40px;
        display: block;
        margin: auto;

        img {
            width: 100%;
        }
    }

    .heroNxt .heroNxtInner {
        padding-bottom: 100px;
        padding-top: 0;
    }

    .solutionWrap ul {
        padding-top: 0;
        margin-top: 0;
    }

    .heroNxt .heroNxtInner h1 {
        font-size: 24px;
    }

    .servicesWrap ul.teamRow li p {
        display: none;
    }

    .caseSliderContainer.caseSliderContainerFooter .slick-center .caseSlideInner {
        height: auto;
    }

    .footerWrap p {
        font-size: 12px;
    }

    .footerWrap .container {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;

        ul li img {
            width: 17px;
        }
    }

    .partnerWrap {
        padding-bottom: 90px;
    }

    .servicesWrap ul {
        padding-bottom: 90px;
    }

    .hippalogo img {
        width: 90%;
        max-width: 90%;
    }
}

@media screen and (min-width: 380px) and (max-width: 576px) {
    .caseSliderContainer .caseSlideInner p {
        font-size: 12px;
        padding-right: 10px;
        // padding-right: 25px;
    }

    .hippalogo img {
        width: 90%;
        max-width: 90%;
    }
}