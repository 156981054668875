@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-ExtraBold.eot');
    src: url('./fonts/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Inter-ExtraBold.woff2') format('woff2'),
        url('./fonts/Inter-ExtraBold.woff') format('woff'),
        url('./fonts/Inter-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-Black.eot');
    src: url('./fonts/Inter-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Inter-Black.woff2') format('woff2'),
        url('./fonts/Inter-Black.woff') format('woff'),
        url('./fonts/Inter-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-Bold.eot');
    src: url('./fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Inter-Bold.woff2') format('woff2'),
        url('./fonts/Inter-Bold.woff') format('woff'),
        url('./fonts/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-Light.eot');
    src: url('./fonts/Inter-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Inter-Light.woff2') format('woff2'),
        url('./fonts/Inter-Light.woff') format('woff'),
        url('./fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-Regular.eot');
    src: url('./fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Inter-Regular.woff2') format('woff2'),
        url('./fonts/Inter-Regular.woff') format('woff'),
        url('./fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-SemiBold.eot');
    src: url('./fonts/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Inter-SemiBold.woff2') format('woff2'),
        url('./fonts/Inter-SemiBold.woff') format('woff'),
        url('./fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-Medium.eot');
    src: url('./fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Inter-Medium.woff2') format('woff2'),
        url('./fonts/Inter-Medium.woff') format('woff'),
        url('./fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-ExtraLight.eot');
    src: url('./fonts/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Inter-ExtraLight.woff2') format('woff2'),
        url('./fonts/Inter-ExtraLight.woff') format('woff'),
        url('./fonts/Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-Thin.eot');
    src: url('./fonts/Inter-Thin.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Inter-Thin.woff2') format('woff2'),
        url('./fonts/Inter-Thin.woff') format('woff'),
        url('./fonts/Inter-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Space Grotesk';
    src: url('./fonts/SpaceGrotesk-Bold.eot');
    src: url('./fonts/SpaceGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SpaceGrotesk-Bold.woff2') format('woff2'),
        url('./fonts/SpaceGrotesk-Bold.woff') format('woff'),
        url('./fonts/SpaceGrotesk-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Grotesk';
    src: url('./fonts/SpaceGrotesk-Light.eot');
    src: url('./fonts/SpaceGrotesk-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SpaceGrotesk-Light.woff2') format('woff2'),
        url('./fonts/SpaceGrotesk-Light.woff') format('woff'),
        url('./fonts/SpaceGrotesk-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Grotesk';
    src: url('./fonts/SpaceGrotesk-Medium.eot');
    src: url('./fonts/SpaceGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SpaceGrotesk-Medium.woff2') format('woff2'),
        url('./fonts/SpaceGrotesk-Medium.woff') format('woff'),
        url('./fonts/SpaceGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Grotesk';
    src: url('./fonts/SpaceGrotesk-SemiBold.eot');
    src: url('./fonts/SpaceGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SpaceGrotesk-SemiBold.woff2') format('woff2'),
        url('./fonts/SpaceGrotesk-SemiBold.woff') format('woff'),
        url('./fonts/SpaceGrotesk-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Grotesk';
    src: url('./fonts/SpaceGrotesk-Regular.eot');
    src: url('./fonts/SpaceGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/SpaceGrotesk-Regular.woff2') format('woff2'),
        url('./fonts/SpaceGrotesk-Regular.woff') format('woff'),
        url('./fonts/SpaceGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-Black.eot');
    src: url('./fonts/Petrona-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-Black.woff2') format('woff2'),
        url('./fonts/Petrona-Black.woff') format('woff'),
        url('./fonts/Petrona-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-Bold.eot');
    src: url('./fonts/Petrona-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-Bold.woff2') format('woff2'),
        url('./fonts/Petrona-Bold.woff') format('woff'),
        url('./fonts/Petrona-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-BlackItalic.eot');
    src: url('./fonts/Petrona-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-BlackItalic.woff2') format('woff2'),
        url('./fonts/Petrona-BlackItalic.woff') format('woff'),
        url('./fonts/Petrona-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-LightItalic.eot');
    src: url('./fonts/Petrona-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-LightItalic.woff2') format('woff2'),
        url('./fonts/Petrona-LightItalic.woff') format('woff'),
        url('./fonts/Petrona-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-ExtraBold.eot');
    src: url('./fonts/Petrona-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-ExtraBold.woff2') format('woff2'),
        url('./fonts/Petrona-ExtraBold.woff') format('woff'),
        url('./fonts/Petrona-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-BoldItalic.eot');
    src: url('./fonts/Petrona-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-BoldItalic.woff2') format('woff2'),
        url('./fonts/Petrona-BoldItalic.woff') format('woff'),
        url('./fonts/Petrona-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-Italic.eot');
    src: url('./fonts/Petrona-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-Italic.woff2') format('woff2'),
        url('./fonts/Petrona-Italic.woff') format('woff'),
        url('./fonts/Petrona-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-ExtraBoldItalic.eot');
    src: url('./fonts/Petrona-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-ExtraBoldItalic.woff2') format('woff2'),
        url('./fonts/Petrona-ExtraBoldItalic.woff') format('woff'),
        url('./fonts/Petrona-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-ExtraLight.eot');
    src: url('./fonts/Petrona-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-ExtraLight.woff2') format('woff2'),
        url('./fonts/Petrona-ExtraLight.woff') format('woff'),
        url('./fonts/Petrona-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-Medium.eot');
    src: url('./fonts/Petrona-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-Medium.woff2') format('woff2'),
        url('./fonts/Petrona-Medium.woff') format('woff'),
        url('./fonts/Petrona-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-ExtraLightItalic.eot');
    src: url('./fonts/Petrona-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-ExtraLightItalic.woff2') format('woff2'),
        url('./fonts/Petrona-ExtraLightItalic.woff') format('woff'),
        url('./fonts/Petrona-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-Light.eot');
    src: url('./fonts/Petrona-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-Light.woff2') format('woff2'),
        url('./fonts/Petrona-Light.woff') format('woff'),
        url('./fonts/Petrona-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-MediumItalic.eot');
    src: url('./fonts/Petrona-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-MediumItalic.woff2') format('woff2'),
        url('./fonts/Petrona-MediumItalic.woff') format('woff'),
        url('./fonts/Petrona-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-Regular.eot');
    src: url('./fonts/Petrona-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-Regular.woff2') format('woff2'),
        url('./fonts/Petrona-Regular.woff') format('woff'),
        url('./fonts/Petrona-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-ThinItalic.eot');
    src: url('./fonts/Petrona-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-ThinItalic.woff2') format('woff2'),
        url('./fonts/Petrona-ThinItalic.woff') format('woff'),
        url('./fonts/Petrona-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-SemiBold.eot');
    src: url('./fonts/Petrona-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-SemiBold.woff2') format('woff2'),
        url('./fonts/Petrona-SemiBold.woff') format('woff'),
        url('./fonts/Petrona-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-Thin.eot');
    src: url('./fonts/Petrona-Thin.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-Thin.woff2') format('woff2'),
        url('./fonts/Petrona-Thin.woff') format('woff'),
        url('./fonts/Petrona-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Petrona';
    src: url('./fonts/Petrona-SemiBoldItalic.eot');
    src: url('./fonts/Petrona-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Petrona-SemiBoldItalic.woff2') format('woff2'),
        url('./fonts/Petrona-SemiBoldItalic.woff') format('woff'),
        url('./fonts/Petrona-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}