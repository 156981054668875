body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 16px;
    line-height: 1.2;
}

body,
html {
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

iframe {
    display: none !important;
}

#youtubeIframe {
    display: flex !important;
}